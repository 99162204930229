.container {
  border-style: solid;
  border-width: 1px;
  width: 8px;
  height: 8px;
  border-color: lightgray;
  margin: 5px;
  border-radius: 3px;
}

.numWrapper {
  display: flex;
  font-size: 12px;
  margin: 4px;
}

.image {
  border-radius: 50%;
  height: 150px;
  width: 150px;
  object-fit: cover;
  border-style: solid;
  border-width: 2px;
  image-rendering: -webkit-optimize-contrast;
  border-color: white;
}

.container {
  max-width: 400px;
  min-width: 300px;
  width: 60%;
  border-radius: 10px;
  border-width: 1px;
  padding: 10px;
  margin: 10px 0px;
  background-color: whitesmoke;
  color: #282c34;
  box-shadow: 0 0px 2px 1px white;
}

.containerAlt {
  max-width: 400px;
  min-width: 300px;
  width: 60%;
  border-radius: 10px;
  border-width: 1px;
  padding: 10px;
  margin: 10px 0px;
  box-shadow: 0 0px 2px 1px white;
}

.headerText {
  font-family: monospace;
  font-size: 2em;
  text-align: left;
}

.bodyText {
  font-family: monospace;
  font-size: 1.2em;
  text-align: left;
}

.window {
  background-color: limegreen;
  border-radius: 10px;
  padding: 5px;
}

.windowAlt {
  padding: 5px 5px 10px 5px;
  border-bottom-width: 3px;
  border-bottom-style: solid;
}

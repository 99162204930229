.wrapper {
  display: flex;
}

.largeFont {
  font-size: 105px;
}

.smallFont {
  font-size: 40px;
}

.Link {
  color: forestgreen;
  font-weight: 600;
}

.Link:visited {
  color: forestgreen;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.umbrella {
  font-size: 1.3em;
  max-width: 400px;
  min-width: 300px;
  width: 60%;
  padding: 10px;
  text-align: left;
}
